import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Rubik\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/components/Auth/AuthButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/components/Auth/SessionProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/components/BrandedHomeButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/components/ScreenSizePopup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/opt/build/repo/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/globals.css");
